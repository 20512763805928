/** @jsx jsx */
import React from "react";
import { jsx, Container, Flex, Image, Link, Box, Button } from "theme-ui";
import Layout from "../components/Layout";

const QualifiedThankYou = () => (
  <Layout >
		<Container sx={{
			height: "100%",
			}}>
			<Flex mb={5} sx={{ margin: "64px auto 80px auto"}}>
					<Box sx={{
							width: "100%",
							maxWidth: "800px",
					}}>
						<Flex sx={{ justifyContent: "center", flexDirection: "column", }}>
							<Box>
								<h1 sx={{fontSize:"40px"}}>
									Thanks for signing up for <br/> GX Cloud Beta, let's get started!
								</h1>
								<p sx={{fontWeight:"bold", fontSize:"20px"}}>
									The next step to get started with Great Expectations Cloud is to meet with our team. Sign up for a demo here:
								</p>
							</Box>
							<Box>
								<a href="/demo">
									<Button 
										type="submit" 
										variant="buttons.primaryV2" 
										sx={{ 
											width:"auto", 
											mb:"32px", 
											mt:"16px",
											p:"16px, 24px", 
											fontSize:"16px", 
											fontWeight:"600", 
											height:"auto",
											borderRadius:"4px",
										}} 
										id="demo-signup"
									>
										Sign up for a demo with our team
									</Button>
								</a>
							</Box>
							<Box>
								<p>
									During our demo, we'll discuss your specific needs and goals, and show you how GX Cloud can help you achieve them. We'll also provide you with the resources you need to get started. 
								</p>
								<p>
									Here are some resources for your GX Beta journey: 
								</p>
								<p>              
									<a 
									href="https://greatexpectations.io/slack/" 
									target="_blank" 
									sx={{color:'#DF570E'}}> 
											Join our community slack
									</a>
								</p>
								<p>              
									<a 
									href="https://docs.greatexpectations.io/docs/tutorials/quickstart/" 
									target="_blank" 
									sx={{color:'#DF570E'}}> 
											Quickstart with Great Expectations (this applies to both open source GX and  GX Cloud)
									</a>
								</p>
							</Box>
						</Flex>
					</Box>
			</Flex>
		</Container>
  </Layout>
);

export default QualifiedThankYou;
